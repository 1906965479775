html,
body {
  min-height: 100%;
  overflow: auto;
}

body {
  position: relative;
  background-size: cover;
}

#left-frame {
  position: fixed;
  float: left;
  left: 0px;
  width: 50px;
  height: 100%;
  z-index: 100;
  background-color: $grey-color-dark;
}

@include media-query($on-medium) {
  #left-frame {
    display: none;
  }
}

/**
 * Site header
 */
.site-header {
  z-index: 100;
  background-color: $grey-color-dark;
  padding-top: calc($spacing-unit / 4);
  padding-bottom: calc($spacing-unit / 2);
  // Positioning context for the mobile navigation icon
  position: relative;
  .wrapper {
    background-color: rgba(0, 0, 0, 0);
  }
}

.site-nav {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  .nav-links {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    flex-wrap: wrap;
    column-gap: $spacing-unit;
  }

  .site-title {
    @include relative-font-size(1.625);
    font-family: $heading-font-family;
    font-weight: 400;
    z-index: 200;
    color: $background-color;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
      color: $background-color;
    }
  }

  .page-link {
    color: $background-color;
    line-height: $base-line-height;
    font-weight: 500;
  }
}

/**
 * Background design
 */
#random-design {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: -100;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/**
 * Site footer
 */
.site-footer {
  background-color: $background-color;
  border-top: 1px solid $grey-color-dark;
  padding: $spacing-unit 0;
  color: grey;
  margin-top: auto;
  margin-bottom: auto;
}

.footer-col-wrapper {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.copyright {
  padding-right: $spacing-unit;
  padding-left: calc($spacing-unit / 2);
  padding-bottom: calc($spacing-unit / 2);
}

/**
 * Images
 */
.body-img {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  min-height: 40vh;
}

.page-heading {
  @include relative-font-size(1.25);
}

.popout-banner {
  margin-left: -2 * $spacing-unit;
  margin-right: -6 * $spacing-unit;
  margin-top: 2 * $spacing-unit;
  margin-bottom: 2 * $spacing-unit;
  padding: $spacing-unit;
  background-color: $grey-color-light;
  border-bottom-right-radius: $spacing-unit;
  border-bottom: 2px solid $grey-color;
  border-right: 2px solid $grey-color;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  h1 {
    text-decoration: underline;
  }
  @include media-query($on-medium) {
    margin-left: auto;
    margin-right: auto;
  }
}

.post-list {
  margin-left: 0;
  list-style: none;
  > li {
    margin-bottom: $spacing-unit;
  }
  > li:before {
    content: unset;
    font-size: unset;
    font-weight: unset;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-medium) {
    @include relative-font-size(2.25);
  }
}

.p {
  margin-bottom: $spacing-unit;
}

.editors-note {
  @include relative-font-size(0.9);
  color: $grey-color;
  margin-bottom: $spacing-unit;
  text-align: right;
}

h2 {
  @include relative-font-size(2);

  @include media-query($on-medium) {
    @include relative-font-size(1.75);
  }
}

h3 {
  @include relative-font-size(1.625);

  @include media-query($on-medium) {
    @include relative-font-size(1.375);
  }
}

h4 {
  @include relative-font-size(1.25);

  @include media-query($on-medium) {
    @include relative-font-size(1.125);
  }
}

p,
a {
  text-align: justify;
  @include media-query($on-palm) {
    text-align: left;
    @include relative-font-size(0.9);
  }
}

li {
  @include media-query($on-palm) {
    text-align: left;
    @include relative-font-size(0.9);
  }
}

.semibold-italic {
  font-weight: 600;
  font-style: italic;
}

.definition {
  margin-left: $spacing-unit;
}

.article-postlude {
  padding-left: 10%;
  border-top: 2px solid $grey-color-light;
  margin-top: $spacing-unit * 2;
  padding-top: $spacing-unit * 2;
}

.fancy-section-title-1 {
  text-align: right;
  border-bottom: 0.15em solid $grey-color-dark;
  margin-left: 38%;
  margin-right: -10%;
  padding-right: 0.3em;
  background: $grey-color-light;
  @include media-query($on-palm) {
    margin-right: 0;
    margin-left: 0;
  }
  @include media-query($on-medium) {
    margin-right: 0;
  }
}

/**
 * Contributors page
 */
.contributors-list {
  > li:before {
    content: unset;
    font-size: unset;
    font-weight: unset;
  }
  li {
    &:not(:first-child) {
      padding-top: $spacing-unit * 0.5;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $grey-color-light;
      padding-bottom: $spacing-unit * 0.5;
    }
  }
  p {
    margin-bottom: 0;
  }
  .contributor-details {
    margin-left: $spacing-unit;
  }
}

/**
 * Book listing
 */
.poetry-excerpt {
  margin-left: $spacing-unit;
  @include relative-font-size(0.8);
}

.poetry-title {
  padding-bottom: calc($spacing-unit / 4);
}

.poetry-line {
  padding-left: 1.5em;
  text-indent: -1.5em;
}

.poetry-stanza {
  padding-top: calc($spacing-unit / 4);
  padding-bottom: calc($spacing-unit / 4);
}

.event-insert {
  padding-top: calc($spacing-unit / 2);
  margin-top: calc($spacing-unit / 2);
  padding-bottom: calc($spacing-unit / 2);
  margin-bottom: calc($spacing-unit / 2);
  border-bottom: 1px solid $grey-color-light;
  border-top: 1px solid $grey-color-light;
}

.quote-citation {
  text-align: right;
}

/**
 * Call To Actions
 */

.call-to-action {
  text-align: center;
  background: $highlight-color;
  color: $background-color;
  padding: calc($spacing-unit / 2);
  border-radius: 10px;

  &:visited {
    color: $background-color;
  }

  //   &:hover {
  //     color: $background-color;
  //     background: $highlight-color-variation;
  //   }
}

.centered-cta-wrapper {
  display: flex;
  justify-content: center;
}

.inverted-text {
  background: $text-color;
  color: $background-color;
}

.announcement {
  position: relative;
  margin: ($spacing-unit * 2) calc($spacing-unit / 2);
  padding: calc($spacing-unit / 2);
  //background-color: $grey-color-light;
  border: dashed 2px $highlight-color;
  color: $highlight-color;

  &:hover {
    color: $highlight-color-variation;
  }
}

.div-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.book-list {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-unit;
  justify-content: center;
  margin-bottom: $spacing-unit;
}

.book-card {
  position: relative;
  flex: 1 1;
  box-sizing: border-box;
  overflow: hidden;
  padding: $spacing-unit;
  min-width: 40%;
  max-width: 800px;

  border: 1px solid $grey-color-dark;

  // Make the whole card act like a link
  // Courtesy of https://stackoverflow.com/a/22074404/5615927
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-decoration: none; /* No underlines on the link */
    z-index: 10; /* Places the link above everything else in the div */
    background-color: #fff; /* Fix to make div clickable in IE */
    opacity: 0; /* Fix to make div clickable in IE */
    filter: alpha(opacity=1); /* Fix to make div clickable in IE */
  }

  .image-container {
    position: relative;
    overflow: hidden;
    background-color: $grey-color-dark;
    height: 0;
    padding-bottom: 56.4%; // Aspect ratio of 1700:960
  }

  .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .image-container img.loaded {
    opacity: 1;
  }

  .description {
    padding-top: $spacing-unit;
  }
}

.book-card img {
  width: 100%;
  height: 100%;
  display: block;
  transition: transform 0.3s ease;
}

.book-card:hover img {
  transform: scale(1.05);
}

.book-superimposed-title-and-author {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: calc($spacing-unit / 4);
  width: 100%;
  text-shadow: 0px 0px 4px black, 0px 0px 8px black;

  h2,
  h3 {
    color: $background-color;
    margin: 0;
  }

  &.dark-text {
    text-shadow: 0px 0px 4px $background-color, 0px 0px 8px $background-color;
    h2,
    h3 {
      color: $grey-color-dark;
    }
  }

  h2 {
    font-size: 1.4em;
  }

  h3 {
    font-size: 1em;
  }
}

.book-info h2 {
  font-size: 1.5em;
  margin: 0;
  font-weight: bold;
}

.book-info h3 {
  font-size: 1.2em;
  font-weight: normal;
  margin: 0;
}

@include media-query($on-medium) {
  .book-card {
    flex: 1 1 100%;
  }
}

@include media-query($on-palm) {
  .book-card {
    flex: 1 1 100%;
  }
}
