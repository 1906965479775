@charset "utf-8";

@use "sass:color";

// Define defaults for each variable.

$base-font-family: "EB Garamond", serif;
$base-font-size: 26px !default;
$base-font-weight: 480 !default;
$tiny-font-size: $base-font-size * 0.6 !default;
$small-font-size: $base-font-size * 0.875 !default;
$larger-font-size: $base-font-size * 1.5 !default;
$base-line-height: 1.4 !default;
$heading-font-family: "DM Serif Display", serif;

$spacing-unit: 30px !default;

$text-color: #010101 !default;
$background-color: #ebe4e0 !default;
$highlight-color: #bf0603 !default;
$highlight-color-variation: #7d0f0d !default;

$grey-color: #828282 !default;
$grey-color-light: color.adjust($grey-color, $lightness: 40%) !default;
$grey-color-dark: color.adjust($grey-color, $lightness: -25%) !default;

// Width of the content area
$content-width: 1200px !default;

$on-palm: 600px !default;
$on-medium: 1200px !default;

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import "minima/base", "minima/layout", "minima/syntax-highlighting", "fonts";
